<template>
  <div class="listOne">
    <div class="image">
      <img :src="indexImg" style="width: 100%; height: 100%" />
    </div>
    <div class="layout">
      <div class="listcontent">
        <!-- <div class="left">
          <div class="listSlid">
            <div class="listSlidHd" @click="menuShow">
              <span>{{ slideHdTitle }}</span>
              <i class="iconfont" v-if="!isMenuShow" style="color: #fff;">&#xe65f; </i>
              <i class="iconfont" v-if="isMenuShow" style="color: #fff;">&#xe65e; </i>
            </div>
            <div class="listSlidList" v-if="isMenuShow">
              <div
                class="listSlidListInner"
                :class="{ on: active == item.entTChannelUuid }"
                v-for="(item, index) in slideList"
                :key="index"
              >
                <a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <a
                  v-if="
                    (!item.url || item.url == '') &&
                      item.entTChannelUuid != '01830005' &&
                      item.entTChannelUuid != '01830001' &&
                      item.entTChannelUuid != '01860001' &&
                      item.entTChannelUuid != '01860004' &&
                      item.entTChannelUuid != '01800005'
                  "
                  :href="
                    item.catalog === 1
                      ? `/listPage/${item.entTChannelUuid}`
                      : item.catalog === 2
                      ? `/list/${item.entTChannelUuid}`
                      : `/listPic/${item.entTChannelUuid}`
                  "
                >
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <a v-if="item.entTChannelUuid == '01830005'" :href="`/listPicTwo/01830005`">
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <a style="display: none" v-if="item.entTChannelUuid == '01800005'" :href="`/list/01800005`">
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>

                <a v-if="item.entTChannelUuid == '01830001'" :href="`/listVideo/01830001`">
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <a v-if="item.entTChannelUuid == '01860001'" :href="`/listVideo/01860001`">
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <a v-if="item.entTChannelUuid == '01860004'" :href="`/listPicTwo/01860004`">
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <span></span>
              </div>
            </div>
          </div>
        </div> -->
        <div class="listMainRight fr">
          <div class="listWrap">
            <div class="listHd clearfloat">
              <div class="fl listHdGrop">
                {{ $t('m.listPageOption') }}：<a href="/" style="font-weight: 700; color: #303133;">{{
                  $t('m.listPageHome')
                }}</a>
                <span v-if="viewSlideHdParent">
                  > <a :href="slideHdParentUrl">{{ slideHdParentName }}</a> ></span
                >
                <span style="color: #0162b3;">&nbsp;{{ slideHdName }}</span>
              </div>
            </div>
            <div class="listContainer">
              <div v-for="(item, index) in listData" :key="index">
                <div class="list">
                  <a v-if="item.url && item.url !== '' && item.url !== null" :href="item.url">
                    <!-- <img v-if="item.thumb1 && item.thumb1 !== '' && item.thumb1 !== null" :src="item.thumb1" class="listImgOne" />
                    <img
                      v-if="!item.thumb1 || item.thumb1 == ''"
                      src="@/assets/image/newspic.jpg"
                      alt=""
                      class="listImgOne"
                    /> -->
                    <div class="listContent">
                      <div class="title">{{ item.title }}</div>
                      <span>{{ item.artDesc }}</span>
                    </div>
                    <div class="time">
                      <p>{{ dayjs(item.publishDate).format('DD') }}</p>
                      <p>{{ dayjs(item.publishDate).format('YYYY-MM') }}</p>
                      <!-- <span>
                        <img src="../assets/image/timSpan.png" alt="" />
                      </span> -->
                    </div>
                  </a>
                  <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`" title="">
                    <!-- <img v-if="item.thumb1 && item.thumb1 !== '' && item.thumb1 !== null" :src="item.thumb1" class="listImgOne" />
                    <img
                      v-if="!item.thumb1 || item.thumb1 == ''"
                      src="@/assets/image/newspic.jpg"
                      alt=""
                      class="listImgOne"
                    /> -->
                    <div class="listContent">
                      <div class="title">{{ item.title }}</div>
                      <span>{{ item.artDesc }}</span>
                    </div>
                    <div class="time">
                      <p>{{ dayjs(item.publishDate).format('DD') }}</p>
                      <p>{{ dayjs(item.publishDate).format('YYYY-MM') }}</p>
                      <!-- <span>
                        <img src="../assets/image/timSpan.png" alt="" />
                      </span> -->
                    </div>
                  </a>
                </div>
              </div>
              <div class="listpagination" v-if="listData.length != 0">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  :pager-count="11"
                  @current-change="currentChange"
                >
                </el-pagination>
              </div>
              <div v-if="listData.length == 0 || this.noempty">
                <el-empty></el-empty>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getChildChannel,
  getChannelInfoNew,
  articles
  // getCookie,
  // getAllArtListByParentUuid
} from '@/api/common'

export default {
  name: 'list',
  data() {
    return {
      isMenuShow: true,
      isthird: false,
      isClickid: '',
      isClickid2: '',
      slideHdName1: '',
      slideHdName2: '',
      slideHdTitle: '',
      viewSlideHdParent: true,
      slideHdParentName: '',
      slideHdParentUrl: '',
      slideHdName: '',
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '新闻动态'
      },
      slideList: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      listData: [],
      listDataEn: [],
      listId: '',
      indexImg: '',
      noempty: false,
      applicationUuid: '',
      lang: 'zh-CN'
    }
  },
  filters: {
    ellipsis: function(value) {
      if (!value) return ''
      if (value.length > 120) {
        return value.slice(0, 120) + '...'
      }
      return value
    },
    ellipsis2: function(value) {
      if (!value) return ''
      if (value.length > 45) {
        return value.slice(0, 45) + '...'
      }
      return value
    }
  },
  mounted() {
    var _this = this
    _this.active = _this.$route.params.id
    _this.listId = _this.$route.params.id
    // let lang = getCookie('lang')
    let lang = localStorage.getItem('lang')
    if (lang == 'en-US') {
      this.applicationUuid = this.webIdEn
    } else {
      this.applicationUuid = this.webId
    }

    _this.getChannelInfoNew()
    _this.articles()
  },
  methods: {
    menuShow() {
      this.isMenuShow = !this.isMenuShow
    },
    // 查询二级栏目
    getChildChannel(id) {
      var _this = this
      let data = {
        applicationUuid: this.applicationUuid,
        parentUuid: id
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideList = data
          if (_this.slideList.length) {
            _this.viewSlideHdParent = true
            let url = ''
            if (_this.slideList[0].catalog === 1) {
              url = `/listPage/${_this.slideList[0].entTChannelUuid}`
            } else if (_this.slideList[0].catalog === 2) {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            } else {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            }
            _this.slideHdParentUrl = url
          } else {
            _this.viewSlideHdParent = false
          }
        }
      })
    },
    getChannelInfoNew() {
      var _this = this
      let data = {
        channelUuid: _this.$route.params.id,
        applicationUuid: this.applicationUuid
      }
      getChannelInfoNew(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideHdName = data.name
          if (data.parentUuid == '0') {
            _this.slideHdTitle = data.name
            _this.viewSlideHdParent = false
            if (data.fileName && data.fileName !== '') {
              _this.indexImg = data.fileName
            } else {
              _this.indexImg = require('../assets/image/partyBuilding.jpg')
            }
            return
          }

          _this.getChildChannel(data.parentUuid)

          let params = {
            channelUuid: data.parentUuid,
            applicationUuid: this.applicationUuid
          }
          getChannelInfoNew(params).then(res => {
            const { code, data } = res
            if (code === 1 && data) {
              _this.slideHdParentName = data.name
              _this.slideHdTitle = data.name
              if (data.fileName && data.fileName !== '') {
                _this.indexImg = data.fileName
              } else {
                _this.indexImg = require('../assets/image/partyBuilding.jpg')
              }
            }
          })
        }
      })
    },
    articles() {
      var _this = this
      let id = ''
      var applicationId = ''
      // 集团新闻
      if (_this.$route.params.id == '01800003') {
        id = '00190001'
        applicationId = _this.webIdGroup
      } else {
        id = _this.$route.params.id
        applicationId = _this.webId
      }
      _this.$i18n.locale = localStorage.getItem('lang') || 'zh-CN'
      if (_this.$i18n.locale == 'en-US') {
        let data = {
          entTApplicationUuid: _this.webIdEn,
          channelUuid: id,
          pageNum: _this.pageNum,
          pageSize: _this.pageSize
        }
        articles(data).then(res => {
          const { code, data } = res
          if (code === 1 && data) {
            _this.listData = data.rows
            _this.total = data.totalRows
          } else {
            this.noempty = true
          }
        })
      } else {
        let data = {
          entTApplicationUuid: applicationId,
          channelUuid: id,
          pageNum: _this.pageNum,
          pageSize: _this.pageSize
        }
        articles(data).then(res => {
          const { code, data } = res
          if (code === 1 && data) {
            _this.listData = data.rows
            _this.total = data.totalRows
          } else {
            this.noempty = true
          }
        })
      }
    },
    currentChange(e) {
      // console.log('e :>> ', e);
      var _this = this
      _this.pageNum = e
      _this.articles()
    }
  }
}
</script>
<style>
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff2?t=1661313604254') format('woff2'),
    url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff?t=1661313604254') format('woff'),
    url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.ttf?t=1661313604254') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}
.listOne .image {
  width: 100%;
  height: 417px;
  overflow: hidden;
  min-width: 1200px;
  max-height: 330px;
  display: flex;
  margin: 0 auto;
  border: none;
  position: relative;
  z-index: 99;
}
.layout {
  width: 1200px;
  margin: 0 auto;
}
.listcontent {
  min-width: 1200px;
  min-height: 300px;
  display: flex;
  margin: 20px 0 30px;
}
.left {
  width: 240px;
}
.listMainRight {
  width: 1180px;
  padding: 0 0 20px 20px;
  overflow: hidden;
}
.listHd {
  height: 50px;
  display: flex;
}
.listHdGrop {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
  opacity: 0.6;
}
.listOne .list {
  width: 1180px;
  height: 164px;
  /* padding: 20px 0; */
  /* display: flex;
  justify-content: space-between; */
  border-top: 1px solid #f1f1f1;
}
.listOne .listContainer .listImgOne {
  width: 238px;
  height: 110px;
  padding-left: 15px;
  float: left;
}
.listOne .listContent {
  overflow: hidden;
  width: 1050px;
  height: 105px;
  float: right;
  margin-top: 34px;
  background-color: #e8e9ee;
  padding: 10px;
  box-sizing: border-box;
}
.listOne .listContent span,
.listContent .title {
  font-family: Microsoft YaHei;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.listOne .listContent span {
  height: auto;
  overflow: hidden;
  color: #6e706d;
  font-size: 13px;
  line-height: 27px;
  -webkit-line-clamp: 2;
}
.listOne .listContent span,
.listContent .title {
  font-family: Microsoft YaHei;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.listOne .listContent .title {
  height: 33px;
  overflow: hidden;
  line-height: 33px;
  font-size: 14px;
  -webkit-line-clamp: 1;
}
.listOne .time {
  width: 110px;
  height: 105px;
  float: left;
  background-color: #f2f2f2;
  margin-top: 34px;
  padding: 10px;
  box-sizing: border-box;
  color: #666;
}
.listOne .time p:nth-child(1) {
  height: 24px;
  overflow: hidden;
  font-size: 26px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 20px;
  text-align: center;
}
.listOne .time p:nth-child(2) {
  height: 24px;
  overflow: hidden;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.listOne .time span {
  display: block;
  width: 50px;
  height: 25px;
  border-left: 2px solid #ddd;
  margin-top: 10px;
  text-align: center;
}
.listOne .time span img {
  width: 25px;
  height: 20px;
  padding-top: 3px;
}
.listOne .listContainer {
  width: 920px;
  height: auto;
}
.listOne .listpagination {
  margin-top: 20px;
}
.listOne .list:hover .time,
.listOne .list:hover .listContent,
.listOne .list:hover .listContent span {
  /* box-shadow: 0 0 24px 0 hsla(0, 0%, 42.7%, 0.4);
  transition-duration: 0.5s; */
  cursor: pointer;
  color: #fff;
  background-color: #0162b3;
}
</style>
